import React, { ReactNode } from "react";

import Loader from "react-loaders";

interface Props {
    hidden?: boolean;
    children: ReactNode;
}
const LoadingIndicator: React.FC<Props> = ({
    hidden = false,
    children,
}: Props) => {
    return (
        <div className="loader-container" hidden={hidden}>
            <div className="loader-container-inner">
                <div className="text-center">
                    <Loader type="ball-grid-beat" active={true} />
                </div>
                <h6 className="mt-3">{children}</h6>
            </div>
        </div>
    );
};

export default LoadingIndicator;
