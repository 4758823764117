import * as Utils from "../../utils/utils";

import { CompanyOverride, setCompanyOverride } from "../../data/gateway";
import React, { useCallback, useEffect, useState } from "react";

import { ICompany } from "../../data/services/companies.types";
import Select from "react-select";
import _ from "lodash";
import constate from "constate";
import cx from "classnames";
import { queryCache } from "react-query";
import { useCompaniesMin } from "../../data/services/companies";
import { useHistory } from "react-router-dom";

interface Props {}

const useCompanyOverrideState = ({ initialState = {} as CompanyOverride }) => {
    const [companyOverrideState, setCompanyOverrideState] =
        useState(initialState);
    return { companyOverrideState, setCompanyOverrideState };
};

function labelLens(company: any) {
    return company.name;
}

function valueLens(company: any) {
    return company.id;
}

export const [
    CompanyOverrideProvider,
    useCompanyOverride,
    useSetCompanyOverride,
] = constate(
    useCompanyOverrideState,
    (value) => value.companyOverrideState,
    (value) => value.setCompanyOverrideState
);

const CompanyOverrideSelect: React.FC<Props> = () => {
    const { resolvedData: companies, status } = useCompaniesMin();
    const overrideState = useCompanyOverride();
    const setCompanyOverrideState = useSetCompanyOverride();
    const history = useHistory();
    const sortedCompaniesEntity = _.sortBy(companies?.entity, ["name"]);
    const [selectedCompany, setSelectedCompany] = useState<
        ICompany | null | undefined
    >();

    useEffect(() => {
        const currentCompany = Utils.getCurrentSelectedCompany();
        if (currentCompany) {
            setSelectedCompany(
                companies?.entity.find(
                    (company) => company.id === currentCompany.id
                )
            );
            return;
        }
        setCompanyOverride({});
        setSelectedCompany(null);
    }, [overrideState, companies]);

    const handleChange = useCallback(
        (change: ICompany | any) => {
            if (!change) {
                Utils.clearCurrentCompany();
                setCompanyOverrideState({});
                queryCache.clear();
                return;
            }
            const { id, companyUUID, name } = change;
            let currentCompany = Utils.getCurrentSelectedCompany();
            if (currentCompany?.id === id) return;
            Utils.setCurrentSelectedCompany(
                JSON.stringify({ id, companyUUID, name })
            );
            const setting = { companyId: id, companyUUID };
            setCompanyOverride(setting);
            setCompanyOverrideState(setting);
            queryCache.clear();
            history.replace(history.location.pathname);
        },
        [history, setCompanyOverrideState]
    );

    const isMultiCompanyEnabled =
        (companies?.entity && companies?.entity?.length > 1) || false;

    return (
        <Select
            options={sortedCompaniesEntity as any}
            className={cx(`CompanyOverrideSelect mt-3`, {
                "d-none": !isMultiCompanyEnabled,
            })}
            isLoading={status === "loading"}
            getOptionLabel={labelLens}
            getOptionValue={valueLens}
            onChange={handleChange}
            value={selectedCompany}
            isClearable
            placeholder={"Select Company..."}
        />
    );
};

export default CompanyOverrideSelect;
