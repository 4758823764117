import React, {
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { differenceInMinutes, isBefore, isFriday } from "date-fns";
import {
    usePermissions,
    useResourcePermission,
} from "./data/services/permissions";

import AppErrorBoundary from "./components/AppErrorBoundary";
import { COPY_WEB_CONTENTS_RESOURCE } from "./utils/permission-resources";
import { CompanyOverrideProvider } from "./components/CompanyOverrideSelect";
// import Dashboard from "./pages/Dashboard";
import ErrorBoundary from "./ErrorBoundary";
import LoadingIndicator from "./components/LoadingIndicator";
import MaintenanceWindow from "./components/MaintenanceWindow";
import NotFound from "./pages/NotFound";
import Page from "./components/Page";
import { Routes } from "./routes";
import { SidebarClosedProvider } from "./components/Sidebar";
import { logoutRedirect } from "./data/gateway";
import ReactGA from "react-ga";

const LazyFallback = (
    <Page noAuth>
        <LoadingIndicator>Loading...</LoadingIndicator>
    </Page>
);

const pages = Routes.map(([path, Component, routeOptions]) => (
    <Route key={path} path={path}>
        <Suspense fallback={LazyFallback}>
            <ErrorBoundary>
                <Component resource={routeOptions.resource} path={path} />
            </ErrorBoundary>
        </Suspense>
    </Route>
));

const App: React.FC = () => {
    const menuClosed = useMemo(() => window.innerWidth < 1250, []);
    const { resolvedData: permissions } = usePermissions({
        refetchInterval: 1000 * 60 * 5,
        refetchIntervalInBackground: true,
    });

    const copyContentPermission = useResourcePermission(
        COPY_WEB_CONTENTS_RESOURCE
    )?.SHARE;

    useEffect(() => {
        if (copyContentPermission) {
            document.body.style.userSelect = "auto";
        }
    }, [copyContentPermission]);
    const minutesUntilMaintenance = useMemo(() => {
        const serverTime = permissions?.headers.date;
        if (serverTime == null) return 60;
        const serverDate = new Date(serverTime);
        // eslint-disable-next-line no-constant-condition
        if (true || !isFriday(serverDate)) return 60;
        const startDate = new Date(serverTime);
        startDate.setUTCHours(19);
        startDate.setUTCMinutes(0);
        startDate.setUTCSeconds(0);
        startDate.setUTCMilliseconds(0);
        const endDate = new Date(startDate);
        endDate.setUTCHours(4);

        return isBefore(serverDate, startDate) && !isBefore(serverDate, endDate)
            ? differenceInMinutes(startDate, serverDate)
            : 0;
    }, [permissions]);

    const [maintenanceAlertOpen, setMaintenanceAlertOpen] = useState(
        minutesUntilMaintenance <= 30 && minutesUntilMaintenance > 0
    );
    const closeMaintenance = useCallback(
        () => setMaintenanceAlertOpen(false),
        []
    );

    ReactGA.initialize("UA-160988529-2");
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <CompanyOverrideProvider>
            <MaintenanceWindow
                minutes={minutesUntilMaintenance}
                isOpen={maintenanceAlertOpen}
                onToggle={closeMaintenance}
                onLogout={logoutRedirect}
            />
            {minutesUntilMaintenance <= 0 ? (
                <div className="p-3 mt-5 text-center">
                    <h3>NetLife Scheduled Maintenance</h3>
                    <p>
                        NetLife is currently undergoing scheduled maintenance.
                    </p>
                </div>
            ) : (
                <Router>
                    <AppErrorBoundary>
                        <SidebarClosedProvider initialState={menuClosed}>
                            <Switch>
                                {/* <Route path="/dashboards/:action?/:id?">
                                    <Suspense fallback={LazyFallback}>
                                        <ErrorBoundary>
                                            <Dashboard />
                                        </ErrorBoundary>
                                    </Suspense>
                                </Route> */}
                                {pages}
                                <Route component={NotFound} />
                            </Switch>
                        </SidebarClosedProvider>
                    </AppErrorBoundary>
                </Router>
            )}
        </CompanyOverrideProvider>
    );
};

export default App;
