import React from 'react';
import { DropdownMenu, Nav, NavItem, Button } from 'reactstrap';

import UserImage from '../UserImage';
import UserInfo from '../UserInfo';
import DarkLightToggler from "../../components/DarkLightToggler";
import { logoutRedirect } from '../../data/gateway';
import { AccountBody } from '../../data/gateway.types';
import avtar from '../../styles/utils/images/avtar.png';

const LogoutButton: React.FC = () => (
  <Button
    className="btn-pill btn-shadow btn-shine"
    color="focus"
    onClick={() => {
      logoutRedirect();
      return false;
    }}
  >
    Logout
  </Button>
);

type UserActionsDropdownMenuProps = {
  account: AccountBody;
};

const UserActionsDropdownMenu: React.FC<UserActionsDropdownMenuProps> = ({
  account,
}) => (
  <DropdownMenu right className="rm-pointers dropdown-menu-lg">
    <div className="dropdown-menu-header">
      <div className="dropdown-menu-header-inner bg-plum-plate">
        <div className="menu-header-image opacity-2" />
        <div className="menu-header-content text-left">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left mr-3">
                <UserImage src={avtar} />
              </div>
              <UserInfo account={account} />
              <div className="widget-content-right mr-2">
                <LogoutButton />
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <h5 className="app-sidebar__heading">Contact Us</h5>
              <DarkLightToggler/>
            </div>
            <div className='user-contact-us-menu'>
              <a
                href="https://meetings.netlifesaas.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="metismenu-icon pe-7s-date " />{" "}
                Schedule a Call
              </a>
              {/* <a
                href="https://erp.netlifesaas.com/helpdesk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="metismenu-icon pe-7s-help1" />{" "}
                Customer Support Desk
              </a> */}
            </div>
            {/* <div className='user-contact-us-menu'>
              <a
                href="https://gsmsystems.atlassian.net/servicedesk/customer/portal/1/group/9/create/30"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="metismenu-icon pe-7s-help1" />{" "}
                Quick Request
              </a>
              <a
                href="https://gsmsystems.atlassian.net/wiki/spaces/GSMHD/overview"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="metismenu-icon pe-7s-help1" />{" "}
                Netlife Wiki
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    <Nav vertical>
      <NavItem className="nav-item-btn text-center">
        <Button size="sm" className="btn-wide" color="primary" disabled>
          Open Messages
        </Button>
      </NavItem>
    </Nav>
  </DropdownMenu>
);

export default UserActionsDropdownMenu;
