import React from 'react';
import { useCompany } from '../../data/services/companies';
import CompanyImage from '../CompanyImage';
import { DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import UserActionsDropdownMenu from '../UserActionsDropdownMenu';
import { useAccount } from '../../data/gateway';

const CompanyOrb: React.FC = () => {
  const { data: initialData } = useCompany();
  const { resolvedData: account, status } = useAccount();
  if (status === 'loading' || account == null) return null;
  return (
    <div className="header-btn-lg pr-0">
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div style={{marginRight: "10px", color: "white"}}>{account?.firstName} {account?.lastName}</div>
          <div className="widget-content-left">
            <UncontrolledButtonDropdown>
              <DropdownToggle color="link" className="p-0">
                <CompanyImage src={initialData?.logoUrl!} />
                <span className="ml-2 opacity-8 fas fa-angle-down" />
              </DropdownToggle>
              <UserActionsDropdownMenu account={account} />
            </UncontrolledButtonDropdown>
          </div>
          {/* <UserInfo account={account} widescreen /> */}
        </div>
      </div>
    </div>
  );
};

export default CompanyOrb;
