import * as ComponentRoutes from "./utils/routes";
import * as PermissionResources from "./utils/permission-resources";
import * as toolTip from "./data/tooltip.json";

import { ComponentType, LazyExoticComponent, lazy } from "react";

import { Action } from "./data/services/permissions.types";

export interface RoutedPageProps {
    resource?: string;
    path: string;
}
export type RouteComponentType =
    | LazyExoticComponent<ComponentType<RoutedPageProps>>
    | ComponentType<RoutedPageProps>;

export type RouteOptions = {
    resource?: string;
    needs: Action[];
    unlisted?: boolean;
    sidebarLink?: string;
};
export type RouteType = [string, string, RouteComponentType, RouteOptions];
export type CustomRoutes = {
    path: string;
    component: RouteComponentType;
};
export type CategoryType = [string, string, string, string, RouteType[]];

export type CategorizedRoutesType = CategoryType[];

function CRUDRoute(
    name: string,
    path: string,
    routeComponent: RouteComponentType,
    options: RouteOptions = { needs: ["GET"] }
): RouteType {
    return [
        name,
        `${path}/:action?/:id?`,
        routeComponent,
        {
            ...options,
            sidebarLink: path,
        },
    ];
}

function Category(
    name: string,
    icon: string,
    description: string,
    descriptionLink: string,
    labeledRoutes: RouteType[]
): CategoryType {
    return [name, icon, description, descriptionLink, labeledRoutes];
}

/**
 * Labeled, categorized routes
 */
export const CategorizedRoutes: CategorizedRoutesType = [
    Category(
        "Dashboards",
        "pe-7s-graph2",
        toolTip.dashboards,
        "/dashboards-manual",
        [
            CRUDRoute(
                "Views",
                ComponentRoutes.DASHBOARD_VIEW_ROUTE,
                lazy(() => import("./pages/Dashboards")),
                {
                    resource: PermissionResources.DASHBOARD_RESOURCE,
                    needs: ["GET"],
                }
            ),
        ]
    ),
    Category(
        "Inventory",
        "pe-7s-note2",
        toolTip.inventory,
        "/inventory-manual",
        [
            CRUDRoute(
                "Search & Filter",
                ComponentRoutes.INVENTORY_VIEW_ROUTE,
                lazy(() => import("./pages/Inventory")),
                {
                    resource: PermissionResources.INVENTORY_RESOURCE,
                    needs: ["GET"],
                }
            ),

            CRUDRoute(
                "Import",
                ComponentRoutes.INVENTORY_IMPORT_ROUTE,
                lazy(() => import("./pages/InventoryImport")),
                {
                    resource: PermissionResources.INVENTORY_IMPORT_RESOURCE,
                    needs: ["GET", "CREATE"],
                }
            ),
            CRUDRoute(
                "Aging",
                ComponentRoutes.INVENTORY_AGING_ROUTE,
                lazy(() => import("./pages/InventoryAging")),
                {
                    resource: PermissionResources.INVENTORY_AGING_RESOURCE,
                    needs: ["GET", "CREATE"],
                }
            ),
            CRUDRoute(
                "Value Analysis",
                ComponentRoutes.VALUE_ANALYSIS_ROUTE,
                lazy(() => import("./pages/ValueAnalysis")),
                {
                    resource: PermissionResources.VALUE_ANALYSIS_RESOURCES,
                    needs: ["CREATE"],
                }
            ),
        ]
    ),
    Category(
        "Marketplace",
        "pe-7s-cart",
        toolTip.marketplace,
        "/requests-manual",
        [
            CRUDRoute(
                "Manage Requests",
                ComponentRoutes.INVENTORY_REQUEST_ROUTE,
                lazy(() => import("./pages/InventoryRequests")),
                {
                    resource: PermissionResources.INVENTORY_REQUEST_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Manage My Requests",
                ComponentRoutes.MY_INVENTORY_REQUEST_ROUTE,
                lazy(() => import("./pages/UserInventoryRequests")),
                {
                    resource: PermissionResources.MY_INVENTORY_REQUEST_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Manage My Orders",
                ComponentRoutes.MY_QUOTATIONS_ROUTE,
                lazy(() => import("./pages/UserQuotations")),
                {
                    resource: PermissionResources.MY_INVENTORY_ORDER_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Create Request",
                ComponentRoutes.INVENTORY_UPLOAD_REQUEST_ROUTE,
                lazy(() => import("./pages/CreateInventoryRequests")),
                {
                    resource:
                        PermissionResources.INVENTORY_UPLOAD_REQUEST_RESOURCE,
                    needs: ["CREATE"],
                }
            ),
            CRUDRoute(
                "Create Instant Request",
                ComponentRoutes.UPLOAD_REQUEST_ROUTE,
                lazy(
                    () =>
                        import(
                            "./pages/CreateInventoryRequests/UploadInventoryRequest"
                        )
                ),
                {
                    resource:
                        PermissionResources.INVENTORY_UPLOAD_INSTANT_REQUEST_RESOURCE,
                    needs: ["CREATE"],
                    unlisted: true,
                }
            ),
            CRUDRoute(
                "Request for Analyst Support",
                ComponentRoutes.SUPPORT_REQUEST_ROUTE,
                lazy(
                    () =>
                        import(
                            "./pages/CreateInventoryRequests/SupportInventoryRequest"
                        )
                ),
                {
                    resource:
                        PermissionResources.INVENTORY_UPLOAD_ANALYST_SUPPORTED_REQUEST_RESOURCE,
                    needs: ["CREATE"],
                    unlisted: true,
                }
            ),
            CRUDRoute(
                "Market Availability",
                ComponentRoutes.MARKET_AVAILABILITY_ROUTE,
                lazy(() => import("./pages/MarketAvailability")),
                {
                    resource: PermissionResources.MARKET_AVAILABILITY_RESOURCE,
                    needs: ["CREATE"],
                }
            ),
            CRUDRoute(
                "Availability Notifications",
                ComponentRoutes.WATCHABLE_INVENTORY_ITEMS_ROUTE,
                lazy(() => import("./pages/PartWishlist")),
                {
                    resource:
                        PermissionResources.WATCHABLE_INVENTORY_ITEMS_RESOURCE,
                    needs: ["GET"],
                }
            ),

            CRUDRoute(
                "Item Search & Quote",
                ComponentRoutes.QUOTATIONS_ROUTE,
                lazy(() => import("./pages/Quotes")),
                {
                    resource: PermissionResources.QUOTATIONS_RESOURCE,
                    needs: ["GET"],
                }
            ),
        ]
    ),
    Category(
        "Fixed Assets",
        "pe-7s-diamond",
        toolTip.fixedAssets,
        "/fixed-assets-manual",
        [
            CRUDRoute(
                "Installed network inventory",
                ComponentRoutes.FIXED_ASSETS_ROUTE,
                lazy(() => import("./pages/FixedAssets")),
                {
                    resource: PermissionResources.FIXED_ASSETS_RESOURCE,
                    needs: ["GET"],
                }
            ),
            // CRUDRoute(
            //     "Site Types",
            //     ComponentRoutes.SITE_TYPES_ROUTE,
            //     lazy(() => import("./pages/SiteType")),
            //     {
            //         resource: PermissionResources.SITE_TYPES_RESOURCE,
            //         needs: ["GET"],
            //     }
            // ),
            CRUDRoute(
                "Sites",
                ComponentRoutes.SITES_ROUTE,
                lazy(() => import("./pages/Sites/Sites")),
                {
                    resource: PermissionResources.SITES_RESOURCE,
                    needs: ["GET"],
                }
            ),
        ]
    ),
    Category(
        "Kits, Configs & Systems",
        "pe-7s-plugin",
        toolTip.buildkits,
        "/buildkits-manual",
        [
            CRUDRoute(
                "Kits",
                ComponentRoutes.KITS_ROUTE,
                lazy(() => import("./pages/Kit")),
                {
                    resource: PermissionResources.KIT_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Configurations & Systems",
                ComponentRoutes.SYSTEM_CONFIGURATION_ROUTE,
                lazy(() => import("./pages/SystemConfigurations")),
                {
                    resource: PermissionResources.SYSTEM_CONFIGURATION_RESOURCE,
                    needs: ["GET"],
                }
            ),
        ]
    ),
    Category(
        "Item Masters",
        "pe-7s-photo-gallery",
        toolTip.itemMasters,
        "/item-masters-manual",
        [
            CRUDRoute(
                "Item Profiles",
                ComponentRoutes.ITEM_MASTER_ROUTE,
                lazy(() => import("./pages/ItemMaster")),
                {
                    resource: PermissionResources.ITEM_MASTER_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Import Item Masters",
                ComponentRoutes.ITEM_MASTER_IMPORT_ROUTE,
                lazy(() => import("./pages/ItemMasterImport")),
                {
                    resource: PermissionResources.IMPORT_ITEM_MASTER_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Bulk Update Items",
                ComponentRoutes.ITEM_MASTER_UPDATE_ROUTE,
                lazy(() => import("./pages/ItemMasterUpdateImport")),
                {
                    resource: PermissionResources.BULK_UPDATE_ITEM_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "OEMs",
                ComponentRoutes.OEM_ROUTE,
                lazy(() => import("./pages/OEM")),
                {
                    resource: PermissionResources.OEM_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "System Categories",
                ComponentRoutes.SYSTEM_CATEGORY_ROUTE,
                lazy(() => import("./pages/SystemCategory")),
                {
                    resource: PermissionResources.SYSTEM_CATEGORY_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Item Category Type",
                ComponentRoutes.ITEM_CATEGORY_TYPE_ROUTE,
                lazy(() => import("./pages/ItemCategoryType")),
                {
                    resource: PermissionResources.ITEM_CATEGORY_TYPE_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "System Types",
                ComponentRoutes.SYSTEM_TYPE_ROUTE,
                lazy(() => import("./pages/SystemType")),
                {
                    resource: PermissionResources.SYSTEM_TYPE_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Item Categories",
                ComponentRoutes.ITEM_CATEGORY_ROUTE,
                lazy(() => import("./pages/ItemCategory")),
                {
                    resource: PermissionResources.ITEM_CATEGORY_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Frequencies",
                ComponentRoutes.FREQUENCY_ROUTE,
                lazy(() => import("./pages/Frequencies")),
                {
                    resource: PermissionResources.FREQUENCY_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Technologies",
                ComponentRoutes.TECHNOLOGY_ROUTE,
                lazy(() => import("./pages/Technologies")),
                {
                    resource: PermissionResources.TECHNOLOGY_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Voltages",
                ComponentRoutes.VOLTAGE_ROUTE,
                lazy(() => import("./pages/Voltages")),
                {
                    resource: PermissionResources.VOLTAGE_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Chargers",
                ComponentRoutes.CHARGER_ROUTE,
                lazy(() => import("./pages/Chargers")),
                {
                    resource: PermissionResources.CHARGER_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Storages",
                ComponentRoutes.STORAGE_ROUTE,
                lazy(() => import("./pages/Storages")),
                {
                    resource: PermissionResources.STORAGE_RESOURCE,
                    needs: ["GET"],
                }
            ),

            CRUDRoute(
                "Units of Measure",
                ComponentRoutes.UOM_ROUTE,
                lazy(() => import("./pages/UOM")),
                {
                    resource: PermissionResources.UOM_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Item Class",
                ComponentRoutes.ITEM_CLASS_ROUTE,
                lazy(() => import("./pages/ItemClass")),
                {
                    resource: PermissionResources.ITEM_CLASS_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Item Class Type",
                ComponentRoutes.ITEM_CLASS_TYPE_ROUTE,
                lazy(() => import("./pages/ItemClassType")),
                {
                    resource: PermissionResources.ITEM_CLASS_TYPE_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Asset Class",
                ComponentRoutes.ASSET_CLASS_ROUTE,
                lazy(() => import("./pages/AssetClass")),
                {
                    resource: PermissionResources.ASSET_CLASS_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Documents",
                ComponentRoutes.DOCUMENT_ROUTE,
                lazy(() => import("./pages/Documents")),
                {
                    resource: PermissionResources.DOCUMENT_RESOURCE,
                    needs: ["GET"],
                }
            ),
        ]
    ),
    Category(
        "Spares Management",
        "pe-7s-repeat",
        toolTip.replacementParts,
        "/replacement-part-manual",
        [
            CRUDRoute(
                "1-4-1 Items",
                ComponentRoutes.EXCHANGED_DEFECTIVE_PARTS_ROUTE,
                lazy(() => import("./pages/ExchangeDefectiveParts")),
                {
                    resource:
                        PermissionResources.EXCHANGED_DEFECTIVE_PART_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Testing",
                ComponentRoutes.DEFECTIVE_PART_ANALYSIS_ROUTE,
                lazy(() => import("./pages/DefectivePartAnalyses")),
                {
                    resource:
                        PermissionResources.DEFECTIVE_PART_ANALYSIS_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "RMA Tracker",
                ComponentRoutes.RMA_TRACKER_ROUTE,
                lazy(() => import("./pages/RmaTracker")),
                {
                    resource: PermissionResources.RMA_TRACKER_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Purchases",
                ComponentRoutes.PURCHASED_ITEMS_ROUTE,
                lazy(() => import("./pages/PurchasedItems")),
                {
                    resource: PermissionResources.PURCHASED_ITEMS_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Backfill",
                ComponentRoutes.REPLACED_INVENTORY_ITEMS_ROUTE,
                lazy(() => import("./pages/ReplacedInventoryItems")),
                {
                    resource:
                        PermissionResources.REPLACED_INVENTORY_ITEMS_RESOURCE,
                    needs: ["GET"],
                }
            ),
            CRUDRoute(
                "Hardware Support Analysis",
                ComponentRoutes.HARDWARE_SUPPORT_ANALYSIS_ROUTE,
                lazy(() => import("./pages/HardwareSupportAnalysis")),
                {
                    resource:
                        PermissionResources.HARDWARE_SUPPORT_ANALYSIS_RESOURCE,
                    needs: ["CREATE"],
                }
            ),
        ]
    ),
    Category("Settings", "pe-7s-config", toolTip.settings, "/settings-manual", [
        CRUDRoute(
            "Asset Value Defaults",
            ComponentRoutes.ASSET_VALUE_DEFAULTS_ROUTE,
            lazy(() => import("./pages/AssetValueDefaults")),
            {
                resource: PermissionResources.ASSET_VALUE_DEFAULTS_RESOURCE,
                needs: ["GET", "CREATE"],
            }
        ),
        CRUDRoute(
            "Inventory Aging Rules",
            ComponentRoutes.INVENTORY_AGING_RULES_ROUTE,
            lazy(() => import("./pages/InventoryAgingRules")),
            {
                resource: PermissionResources.INVENTORY_AGING_RULE_RESOURCE,
                needs: ["GET", "CREATE"],
            }
        ),
        CRUDRoute(
            "Inventory Request Fields",
            ComponentRoutes.COMPANY_INVENTORY_REQUEST_FIELDS_ROUTE,
            lazy(() => import("./pages/CompanyInventoryRequestFields")),
            {
                resource:
                    PermissionResources.COMPANY_INVENTORY_REQUEST_FIELDS_RESOURCE,
                needs: ["GET", "CREATE"],
            }
        ),
    ]),
    Category("Setup", "lnr-apartment", toolTip.setup, "/companies-manual", [
        CRUDRoute(
            "Users",
            ComponentRoutes.USERS_ROUTE,
            lazy(() => import("./pages/User")),
            {
                resource: PermissionResources.USERS_RESOURCE,
                needs: ["GET", "CREATE"],
            }
        ),
        CRUDRoute(
            "Contacts",
            ComponentRoutes.CONTACTS_ROUTE,
            lazy(() => import("./pages/Contacts")),
            {
                resource: PermissionResources.CONTACTS_RESOURCE,
                needs: ["GET"],
            }
        ),
        CRUDRoute(
            "Groups",
            ComponentRoutes.GROUPS_ROUTE,
            lazy(() => import("./pages/Groups")),
            {
                resource: PermissionResources.GROUPS_RESOURCE,
                needs: ["GET", "CREATE"],
            }
        ),
        CRUDRoute(
            "Notification Type",
            ComponentRoutes.NOTIFICATION_TYPE_ROUTE,
            lazy(() => import("./pages/NotificationType")),
            {
                resource: PermissionResources.NOTIFICATION_TYPE_RESOURCE,
                needs: ["GET"],
            }
        ),
        CRUDRoute(
            "Dashboard Settings",
            ComponentRoutes.DASHBOARD_SETTING_ROUTE,
            lazy(() => import("./pages/DashboardSettings")),
            {
                resource: PermissionResources.DASHBOARD_RESOURCE,
                needs: ["GET", "CREATE"],
            }
        ),
        CRUDRoute(
            "Menu Help Videos",
            ComponentRoutes.MENU_HELP_VIDEOS_ROUTE,
            lazy(() => import("./pages/MenuHelpVideos")),
            {
                resource: PermissionResources.MENU_HELP_VIDEO_RESOURCE,
                needs: ["GET", "CREATE"],
            }
        ),
        // CRUDRoute(
        //     "Manuals",
        //     ComponentRoutes.USER_MANUALS_ROUTE,
        //     lazy(() => import("./pages/Manuals")),
        //     {
        //         resource: PermissionResources.USER_MANUALS_RESOURCE,
        //         needs: ["GET"],
        //     }
        // ),
        CRUDRoute(
            "Releases",
            ComponentRoutes.CHANGELOG_ROUTE,
            lazy(() => import("./pages/Changelog/Changelog")),
            {
                resource: PermissionResources.CHANGELOG_RESOURCE,
                needs: ["GET"],
            }
        ),
        CRUDRoute(
            "Login Activities",
            ComponentRoutes.USER_ACTIVITY_ROUTE,
            lazy(() => import("./pages/UserActivity")),
            {
                resource: PermissionResources.USER_ACTIVITY_RESOURCE,
                needs: ["GET"],
            }
        ),
        CRUDRoute(
            "Companies",
            ComponentRoutes.COMPANY_ROUTE,
            lazy(() => import("./pages/Companies")),
            {
                resource: PermissionResources.COMPANY_RESOURCE,
                needs: ["GET", "CREATE", "UPDATE"],
            }
        ),
        CRUDRoute(
            "Company Types",
            ComponentRoutes.COMPANY_TYPES_ROUTE,
            lazy(() => import("./pages/CompanyTypes")),
            {
                resource: PermissionResources.COMPANY_TYPES_RESOURCE,
                needs: ["GET"],
            }
        ),
        CRUDRoute(
            "Company Networks",
            ComponentRoutes.COMPANY_NETWORK_ROUTE,
            lazy(() => import("./pages/CompanyNetworks")),
            {
                resource: PermissionResources.COMPANY_NETWORK_RESOURCE,
                needs: ["GET"],
            }
        ),
        CRUDRoute(
            "Company Groups",
            ComponentRoutes.COMPANY_GROUP_ROUTE,
            lazy(() => import("./pages/CompanyGroup")),
            {
                resource: PermissionResources.COMPANY_GROUP_RESOURCE,
                needs: ["GET"],
            }
        ),
        CRUDRoute(
            "Currencies",
            ComponentRoutes.CURRENCY_ROUTE,
            lazy(() => import("./pages/Currencies")),
            {
                resource: PermissionResources.CURRENCY_RESOURCE,
                needs: ["GET", "CREATE"],
            }
        ),
        CRUDRoute(
            "Warehouses",
            ComponentRoutes.WAREHOUSE_ROUTE,
            lazy(() => import("./pages/Warehouses")),
            {
                resource: PermissionResources.WAREHOUSE_RESOURCE,
                needs: ["GET"],
            }
        ),
        CRUDRoute(
            "Countries",
            ComponentRoutes.COUNTRY_ROUTE,
            lazy(() => import("./pages/Countries")),
            {
                resource: PermissionResources.COUNTRY_RESOURCE,
                needs: ["GET"],
            }
        ),
    ]),
];
const NonCategorizedUserManualRoutes: Array<CustomRoutes> = [
    {
        path: "/dashboards-manual",
        component: lazy(() => import("./pages/UserManual")),
    },
    {
        path: "/inventory-manual",
        component: lazy(() => import("./pages/UserManual")),
    },
    {
        path: "/kits-manual",
        component: lazy(() => import("./pages/UserManual")),
    },
    {
        path: "/asset-value-defaults-manual",
        component: lazy(() => import("./pages/UserManual")),
    },
    {
        path: "/item-masters-manual",
        component: lazy(() => import("./pages/UserManual")),
    },
    {
        path: "/users-manual",
        component: lazy(() => import("./pages/UserManual")),
    },
    {
        path: "/item-masters-manual",
        component: lazy(() => import("./pages/UserManual")),
    },
    {
        path: "/companies-manual",
        component: lazy(() => import("./pages/UserManual")),
    },
    {
        path: "/data-connections-manual",
        component: lazy(() => import("./pages/UserManual")),
    },
];

export const Routes: Array<[string, RouteComponentType, RouteOptions]> = [];

//populate
for (let i = 0; i < NonCategorizedUserManualRoutes.length; i++) {
    const router = NonCategorizedUserManualRoutes[i];
    Routes.push([router.path, router.component, { needs: ["GET"] }]);
}

// Populate the raw routes
for (let i = 0; i < CategorizedRoutes.length; i++) {
    const [, , , , routes] = CategorizedRoutes[i];
    for (let j = 0; j < routes.length; j++) {
        const [, path, component, options] = routes[j];
        Routes.push([path, component, options]);
    }
}

export function getRoute(path: string) {
    return CategorizedRoutes.find((categorizedRoute) =>
        categorizedRoute[4].find(
            (route) =>
                route[1].startsWith(path.concat("/")) ||
                route[1].startsWith(path)
        )
    );
}

export function getResourceFromPath(path: string) {
    if (!path) return undefined;
    let resource: string | undefined;
    CategorizedRoutes.forEach((categorizedRoute) => {
        const matchingRoute = categorizedRoute[4].find(
            (route) =>
                route[1]?.startsWith(path.concat("/")) ||
                route[1]?.startsWith(path)
        );
        if (!resource && matchingRoute) {
            resource = matchingRoute[3].resource;
            return;
        }
    });
    return resource;
}
